import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import serv_1 from "./assets/icons/service/ICO_BAZAR.png";
import serv_2 from "./assets/icons/service/ICO_TOKEN.png";
import serv_3 from "./assets/icons/service/ICO_ARTCHAIN.png";
import serv_4 from "./assets/icons/service/ICO_HUB.png";
import Link from "./assets/Link.png";
import Research from "./assets/Research.png";
import MetaMask from "./assets/MetaMask.png";

const artWorksData = [
  {
    id: 1,
    name: "Ocean Waves",
    description: "Description of Ocean Waves",
    artist: "Alice Johnson",
    year: 2018,
    type: ["Digital", "Physical"],
    sizeDigital: ["1024x1400", "2048x2800", "4096x5600"],
    sizePhysical: ["13cmx15cm", "16cmx19cm", "23cmx27cm"],
    certification: "Multiple",
    priceDigital: 1400,
    pricePhysical: 1600,
    tags: ["ocean", "music", "abstract"],
    publishing_data: "2021-05-20",
  },
  {
    id: 2,
    name: "Starry Night",
    description: "Description of Starry Night",
    artist: "Bob Smith",
    year: 2022,
    type: ["Digital"],
    sizeDigital: ["1024x1400", "2048x2800", "4096x5600"],
    sizePhysical: [],
    certification: "Single",
    priceDigital: 1200,
    pricePhysical: 1400,
    tags: ["paint", "space"],
    publishing_data: "2022-08-10",
  },
  {
    id: 3,
    name: "City Lights",
    description: "Description of City Lights",
    artist: "Charlie Brown",
    year: 2018,
    type: ["Digital", "Physical"],
    sizeDigital: ["1024x1400", "2048x2800", "4096x5600"],
    sizePhysical: ["13cmx15cm", "16cmx19cm", "23cmx27cm"],
    certification: "Multiple",
    priceDigital: 1400,
    pricePhysical: 1600,
    tags: ["nature", "photography", "city"],
    publishing_data: "2023-01-30",
  },
  {
    id: 4,
    name: "Abstract Reflections",
    description: "Description of Abstract Reflections",
    artist: "David White",
    year: 2023,
    type: ["Digital", "Physical"],
    sizeDigital: ["1024x1400", "2048x2800", "4096x5600"],
    sizePhysical: ["13cmx15cm", "16cmx19cm", "23cmx27cm"],
    certification: "Multiple",
    priceDigital: 1600,
    pricePhysical: 1800,
    tags: ["history", "abstract"],
    publishing_data: "2020-11-15",
  },
  {
    id: 5,
    name: "Serenity",
    description: "Description of Serenity",
    artist: "Alice Johnson",
    year: 2022,
    type: ["Digital", "Physical"],
    sizeDigital: ["1024x1400", "2048x2800", "4096x5600"],
    sizePhysical: ["13cmx15cm", "16cmx19cm", "23cmx27cm"],
    certification: "Multiple",
    priceDigital: 1500,
    pricePhysical: 1700,
    tags: ["ocean", "music", "abstract"],
    publishing_data: "2020-07-05",
  },
  {
    id: 6,
    name: "Mystic Forest",
    description: "Description of Mystic Forest",
    artist: "Olivia King",
    year: 2021,
    type: ["Digital", "Physical"],
    sizeDigital: ["1024x1400", "2048x2800", "4096x5600"],
    sizePhysical: ["13cmx15cm", "16cmx19cm", "23cmx27cm"],
    certification: "Multiple",
    priceDigital: 1500,
    pricePhysical: 1700,
    tags: ["nature", "photography", "city"],
    publishing_data: "2020-10-12",
  },
  {
    id: 7,
    name: "Enchanted Lake",
    description: "Description of Enchanted Lake",
    artist: "Frank Wilson",
    year: 2019,
    type: ["Digital"],
    sizeDigital: ["1024x1400", "2048x2800", "4096x5600"],
    sizePhysical: ["13cmx15cm", "16cmx19cm"],
    certification: "Single",
    priceDigital: 1100,
    pricePhysical: 1300,
    tags: ["nature", "music"],
    publishing_data: "2021-12-18",
  },
  {
    id: 8,
    name: "Celestial Harmony",
    description: "Description of Celestial Harmony",
    artist: "Grace Lee",
    year: 2021,
    type: ["Physical"],
    sizeDigital: ["1024x1400", "2048x2800"],
    sizePhysical: ["13cmx15cm", "16cmx19cm"],
    certification: "Single",
    priceDigital: 1300,
    pricePhysical: 1500,
    tags: ["paint", "space"],
    publishing_data: "2022-02-25",
  },
  {
    id: 9,
    name: "Timeless Beauty",
    description: "Description of Timeless Beauty",
    artist: "Henry Martin",
    year: 2021,
    type: ["Physical"],
    sizeDigital: ["1024x1400", "2048x2800"],
    sizePhysical: ["13cmx15cm", "16cmx19cm"],
    certification: "Single",
    priceDigital: 1300,
    pricePhysical: 1500,
    tags: ["nature", "history"],
    publishing_data: "2023-09-03",
  },
  {
    id: 10,
    name: "Cosmic Elegance",
    description: "Description of Cosmic Elegance",
    artist: "Isabel Adams",
    year: 2020,
    type: ["Physical"],
    sizeDigital: ["1024x1400", "2048x2800"],
    sizePhysical: ["13cmx15cm", "16cmx19cm"],
    certification: "Single",
    priceDigital: 1100,
    pricePhysical: 1300,
    tags: ["paint", "space"],
    publishing_data: "2021-03-29",
  },
  {
    id: 11,
    name: "Mystical Mountains",
    description: "Description of Mystical Mountains",
    artist: "Olivia King",
    year: 2022,
    type: ["Digital", "Physical"],
    sizeDigital: ["1024x1400", "2048x2800", "4096x5600"],
    sizePhysical: ["13cmx15cm", "16cmx19cm", "23cmx27cm"],
    certification: "Multiple",
    priceDigital: 1500,
    pricePhysical: 1700,
    tags: ["music", "abstract"],
    publishing_data: "2023-08-14",
  },
  {
    id: 12,
    name: "Ancient Ruins",
    description: "Description of Ancient Ruins",
    artist: "Katherine Clark",
    year: 2018,
    type: ["Digital", "Physical"],
    sizeDigital: ["1024x1400", "2048x2800"],
    sizePhysical: ["13cmx15cm", "16cmx19cm", "23cmx27cm"],
    certification: "Multiple",
    priceDigital: 1400,
    pricePhysical: 1600,
    tags: ["nature", "history", "ocean"],
    publishing_data: "2020-08-22",
  },
  {
    id: 13,
    name: "Urban Exploration",
    description: "Description of Urban Exploration",
    artist: "Liam Scott",
    year: 2019,
    type: ["Digital"],
    sizeDigital: ["1024x1400", "2048x2800", "4096x5600"],
    sizePhysical: ["13cmx15cm", "16cmx19cm"],
    certification: "Single",
    priceDigital: 1100,
    pricePhysical: 1300,
    tags: ["photography", "city"],
    publishing_data: "2023-02-10",
  },
  {
    id: 14,
    name: "Vibrant Horizons",
    description: "Description of Vibrant Horizons",
    artist: "Mia Hall",
    year: 2020,
    type: ["Digital"],
    sizeDigital: ["1024x1400", "2048x2800", "4096x5600"],
    sizePhysical: [],
    certification: "Single",
    priceDigital: 1200,
    pricePhysical: 1400,
    tags: ["paint", "space"],
    publishing_data: "2022-04-25",
  },
  {
    id: 15,
    name: "Lunar Serenity",
    description: "Description of Lunar Serenity",
    artist: "Noah Harris",
    year: 2019,
    type: ["Digital"],
    sizeDigital: ["1024x1400", "2048x2800", "4096x5600"],
    sizePhysical: ["13cmx15cm", "16cmx19cm"],
    certification: "Single",
    priceDigital: 1100,
    pricePhysical: 1300,
    tags: ["nature", "history"],
    publishing_data: "2021-11-05",
  },
  {
    id: 16,
    name: "Celestial Dreams",
    description: "Description of Celestial Dreams",
    artist: "Olivia King",
    year: 2020,
    type: ["Digital"],
    sizeDigital: ["1024x1400", "2048x2800", "4096x5600"],
    sizePhysical: [],
    certification: "Single",
    priceDigital: 1200,
    pricePhysical: 1400,
    tags: ["nature", "music"],
    publishing_data: "2021-06-28",
  },
  {
    id: 17,
    name: "Galactic Odyssey",
    description: "Description of Galactic Odyssey",
    artist: "Peter Roberts",
    year: 2021,
    type: ["Physical"],
    sizeDigital: ["1024x1400", "2048x2800"],
    sizePhysical: ["13cmx15cm", "16cmx19cm"],
    certification: "Single",
    priceDigital: 1300,
    pricePhysical: 1500,
    tags: ["paint", "space"],
    publishing_data: "2022-10-17",
  },
  {
    id: 18,
    name: "Eternal Beauty",
    description: "Description of Eternal Beauty",
    artist: "Olivia King",
    year: 2019,
    type: ["Digital"],
    sizeDigital: ["1024x1400", "2048x2800", "4096x5600"],
    sizePhysical: ["13cmx15cm", "16cmx19cm"],
    certification: "Single",
    priceDigital: 1100,
    pricePhysical: 1300,
    tags: ["nature", "history"],
    publishing_data: "2023-03-02",
  },
];

const artistData = [
  {
    id: 1,
    firstName: "John",
    lastName: "Wick",
    biography: "John Doe is a talented artist known for his unique style...",
    ArtsID: [1, 3, 5],
  },
  {
    id: 2,
    firstName: "Albert",
    lastName: "Wesker",
    biography:
      "Jane Smith is a renowned artist with a passion for abstract art...",
    ArtsID: [2, 4],
  },
];

const about_section = [
  {
    name: <p>More About Funooni</p>,
    text: (
      <p>
        Funooni is a cutting-edge project by FunoonArab, designed to spotlight
        artists and their creativity, with a special focus on traditional Arabic
        art. Whether you're a painter, photographer, sculptor, designer, or
        content creator, Funooni provides a dynamic stage to showcase your
        talent, connect with your audience, collaborate with fellow artists, and
        share your work globally. Funooni is where innovation meets tradition,
        allowing artists to explore, thrive, and make their mark in the creative
        universe.
      </p>
    ),
  },
];

const boxes = [
  {
    img: serv_1,
    title: "NFT Bazaar",
    content:
      "Where we transform valuable physical artworks into unique digital assets, secured and authenticated via blockchain. Here, you can buy and sell these exclusive digital pieces, expanding your collection and engaging with a community of art lovers.",
  },
  {
    img: serv_2,
    title: "Tree Token",
    content:
      "Where you can support global reforestation and help sustain farming communities with our digital tokens that confirm your environmental impact.",
  },
  {
    img: serv_3,
    title: "ArtChain",
    content:
      "Where you can discover a selection of authenticated physical art pieces that cater to both contemporary and traditional tastes.",
  },
  {
    img: serv_4,
    title: "Heritage Hub",
    content:
      "A platform dedicated to preserving and celebrating cultural arts through advanced digitization, ensuring that precious heritage from all across the world is safeguarded for future generations.",
  },
];
const servicesList = [
  {
    name: <>NFT Bazaar</>,
    img: serv_1,
    description: (
      <>
        Where we transform valuable physical artworks into unique digital
        assets, secured and authenticated via blockchain. Here, you can buy and
        sell these exclusive digital pieces, expanding your collection and
        engaging with a community of art lovers.
      </>
    ),
  },
  {
    name: <>Tree Token</>,
    img: serv_2,
    description: (
      <>
        Where you can support global reforestation and help sustain farming
        communities with our digital tokens that confirm your environmental
        impact.
      </>
    ),
  },
  {
    name: <>ArtChain</>,
    img: serv_3,
    description: (
      <>
        Where you can discover a selection of authenticated physical art pieces
        that cater to both contemporary and traditional tastes.
      </>
    ),
  },
  {
    name: <>Heritage Hub</>,
    img: serv_4,
    description: (
      <>
        A platform dedicated to preserving and celebrating cultural arts through
        advanced digitization, ensuring that precious heritage from all across
        the world is safeguarded for future generations.
      </>
    ),
  },
];

const servDescription = {
  title: <p>Our Services</p>,
  desc1: (
    <p>
      At Funooni, we are dedicated to blending the realms of traditional and
      digital art. Here’s a look at the diverse services we offer:
    </p>
  ),
  desc2: (
    <p>
      Artists interested in transforming their physical artwork into digital art
      or NFTs are invited to reach out via <b>support@funoonarab.com</b> for
      personalized assistance in entering the digital art market. The team will
      then get back to the artists with more information on the process and
      costs involved.
    </p>
  ),
};

const textContainer = [
  {
    index: 3,
    title: <p>More About Funooni </p>,
    textContent: (
      <p>
        Funooni is a cutting-edge project by{" "}
        <a className="simpl_link" href="https://funoonarab.com/">
          FunoonArab
        </a>
        , designed to spotlight artists and their creativity, with a special
        focus on traditional Arabic art. Whether you're a painter, photographer,
        sculptor, designer, or content creator, Funooni provides a dynamic stage
        to showcase your talent, connect with your audience, collaborate with
        fellow artists, and share your work globally. Funooni is where
        innovation meets tradition, allowing artists to explore, thrive, and
        make their mark in the creative universe.
      </p>
    ),
  },
];

const teamMembers = [
  {
    name: "Raed Taha",
    role: "CEO & Director of Funooni",
    description:
      "Raed has over 25 years of experience in finance, ICT, and Fintech, Raed Taha has held senior roles at leading global companies, including Nour Smart Solutions and JP Morgan Chase Bank. A visionary leader, he excels in driving innovation, fostering business relationships, and delivering transformative solutions.",
  },
  {
    name: "Matvey Korovkin",
    role: "Senior Front Developer",
    description:
      "Matvey has over a decade of comprehensive experience in 3D design, programming, and game development to our team.  Skilled in a variety of software including Cinema 4D, Unreal Engine, and Adobe Suite, Matvey specializes in integrating complex 3D visualizations and interactive front-end designs that enhance user experiences on digital platforms.",
  },
  {
    name: "Ghazi Inaim",
    role: "Art Director",
    description:
      "Ghazi, has a Bachelor of Fine Arts from Damascus University and a PhD from the Global Initiative for Humanitarian Leadership, is a distinguished artist and cultural leader. He has served as President of the Jordanian Fine Artists Association and co-founded the Union of Arab Fine Artists, where he was elected Vice President. As the founder of Arab Fine Arts Magazine, Ghazi is also an active member of various writers' associations and editorial boards.",
  },
  {
    name: "Raghda Al-Shaikh Ali",
    role: "Front-end Developer",
    description:
      "Raghda Has over 4 years of experience in web development and quality assurance, where she applied best practices and industry standards to real-world projects and QA training. She has also earned certifications in front-end development (React.js) and back-end development (Node.js), enhancing her skills and driving her impactful contributions to Funooni.",
  },
  {
    name: "Rana El-Shaqra",
    role: "Digital Marketing Officer",
    description:
      "Rana has over 7 years of experience in digital marketing, administration, and project management, Rana has worked with diverse companies locally and globally. Specializing in business development, she excels in enhancing digital content, driving client engagement, and achieving results through strategic sales and communication.",
  },

  {
    name: "Alex Andaliev",
    role: "Python Developer",
    description:
      "Alex has more than 3 years of experience in developing various software. He has successfully implemented several large-scale projects, beginning with the development of scientific programs and subsequently transitioning to end-to-end development, including web applications and distributed systems.",
  },
];

const stepsData = [
  {
    image: MetaMask,
    title: "Create a MetaMask Wallet",
    content: (
      <>
        To start your journey, you’ll need a wallet; setting one up is easier
        than ever! You have two options:
      </>
    ),
    list: [
      "Download and install the MetaMask extension for your browser.",
      <a href="https://metamask.io" target="_blank">
        Get MetaMask <b>here</b>
      </a>,
    ],
  },
  {
    image: Link,
    title: "Connect Your Wallet",
    content: "Once your MetaMask wallet is ready:",
    list: [
      <p>
        Click the <b>Connect Wallet</b> button in the navigation bar.
      </p>,
      "Approve the connection request in MetaMask.",
      "After approval, your NFT account will be automatically created!",
    ],
  },
  {
    image: Research,
    title: "Explore NFTs",
    content: (
      <p>
        You're all set to experience the magic! Head to our <b>NFT Page</b> to
        discover and purchase stunning artwork.
      </p>
    ),
  },
];
const optionalInfo = [
  {
    title: "Alternative login",
    description: (
      <p>
        Use the burger menu to log in with your email, then connect MetaMask
        through your profile or the <b>Connect Wallet</b> button.
      </p>
    ),
  },
  {
    title: "Subscribe to our newsletter",
    description:
      "Visit your User Profile and connect your email to stay updated with the latest news and drops.",
  },
];
export {
  optionalInfo,
  stepsData,
  teamMembers,
  artWorksData,
  artistData,
  servicesList,
  about_section,
  servDescription,
  textContainer,
  boxes,
};
