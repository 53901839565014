import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import "./About.scss";
import logo from "../../assets/logo.png";
import "../../components/BoxCard/BoxCard.scss";

const TeamMemberCard = ({ title, role, content, maxWords }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleContent = () => setIsExpanded(!isExpanded);

  const truncatedContent =
    content.split(" ").slice(0, maxWords).join(" ") +
    (content.split(" ").length > maxWords ? "..." : "");

  return (
    <div className="team-description">
      <div className="team-description-content">
        <div className="team-description_img">
          <img src={logo} />
        </div>
        <div className={`team-description-text`}>
          <h3>{title}</h3>
          <p>{role}</p>
        </div>
      </div>
      <div
        className={`team-content ${isExpanded ? "expanded" : ""}`}
        onMouseEnter={() => setIsExpanded(true)}
        onMouseLeave={() => setIsExpanded(false)}
      >
        <p>{isExpanded ? content : truncatedContent}</p>
        <button className="box-toggle" onClick={toggleContent}>
          <IoIosArrowDown
            className={`arrow-icon ${isExpanded ? "rotated" : ""}`}
          />
        </button>
      </div>
    </div>
  );
};

export default TeamMemberCard;
