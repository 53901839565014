import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import axios from "axios";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SliderArticle.scss";
import { formatName, hostURL } from "../../data_requests";

const ArticleSlider = () => {
  const [mediaData, setMediaData] = useState([]);

  useEffect(() => {
    const fetchMediaData = async () => {
      try {
        const response = await axios.get(`${hostURL}media`);
        setMediaData(response.data);
      } catch (error) {
        console.error("Error fetching media data:", error);
      }
    };

    fetchMediaData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows: false,

    customPaging: (i) => (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            width: "30px",
            height: "6px",
            background: "white",
            boxShadow:
              "1px 1px 1px black, -1px -1px 1px black, 1px -1px 2px black, -1px 1px 2px black",
          }}
        ></div>
      </div>
    ),
  };

  return (
    <div className="article-slider-container">
      <Slider {...settings}>
        {mediaData.map((media, index) => (
          <div className="article-slider-item" key={media.id}>
            {media.img_url && media.title ? (
              <div
                className="article-slider-image"
                style={{
                  backgroundImage: `url(${media.img_url})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  width: "100%",
                }}
              >
                <div className="article-slider_content">
                  <div className="article-content">
                    <h3>{media.title}</h3>
                    <p>{media.sub_title}</p>
                  </div>
                  <Link
                    to={`/media/${media.id}`}
                    className="article-view-collection-button"
                  >
                    Check News
                  </Link>
                  <div className="article-left-paragraph">
                    <p>
                      {media.short_description.length > 200
                        ? media.short_description.slice(0, 200) + "..."
                        : media.short_description}
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div>No article available</div>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ArticleSlider;
