import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Auth.scss";
import { useNavigate, Link } from "react-router-dom";
import { HiArrowUturnRight } from "react-icons/hi2";

import {
  postLogin,
  verifyLogin,
  registerUser,
  postCheckCode,
  postResendCode,
  sendPasswordResetRequest,
} from "../../data_requests";

const Auth = () => {
  const [currentView, setCurrentView] = useState("login");
  const [formData, setFormData] = useState({
    email: "",
    username: "",
    password: "",
    confirmPassword: "",
    verificationCode: "",
  });
  const [errorMessage, setErrorMessage] = useState("");

  const [loading, setLoading] = useState(false);
  const codeRefs = useRef([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentView === "verify") {
      const storedEmail = localStorage.getItem("email");
      if (storedEmail) {
        setFormData((prev) => ({ ...prev, email: storedEmail }));
      } else {
        toast.error("Email not found. Please register again.");
        setCurrentView("register");
      }
    }
  }, [currentView]);

  const handleChange = (e) => {
    const { name, value } = e.target;
  if (name === "email") {
    setFormData((prev) => ({ ...prev, [name]: value.toLowerCase() }));
  } else {
    setFormData((prev) => ({ ...prev, [name]: value }));
  }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    const { email, password } = formData;

    if (!email || !password) {
      toast.error("Please enter both email and password.");
      return;
    }
    setLoading(true);
    const loginResult = await postLogin(email, password);
    setLoading(false);

    if (!loginResult.success) {
      toast.error(loginResult.errorMessage);
      return;
    }

    const verifyResult = await verifyLogin();
    if (verifyResult.success) {
      toast.success("Login successful!");
      navigate("/profile");
    } else {
      toast.error(verifyResult.errorMessage);
    }
  };

  const handleSendRecoveryLink = async (e) => {
    e.preventDefault();
    if (!formData.email) {
      toast.error("Please enter your email address.");
      return;
    }
    setLoading(true);
    try {
      const result = await sendPasswordResetRequest(formData.email);
      if (result.status === "successful") {
        toast.success("Recovery link sent to your email.");
        setTimeout(() => {
          setCurrentView("login");
        }, 6000);
      } else {
        setErrorMessage(
          "The email address does not exist. Please check your email or register if you don't have an account."
        );
      }
    } catch (error) {
      setErrorMessage(
        "The email address does not exist. Please check your email or register if you don't have an account."
      );
    }
    setLoading(false);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    const { email, username, password, confirmPassword } = formData;
  
   
    const passwordRegex = /^(?=.*[A-Za-z]).{8,}$/;
    if (!passwordRegex.test(password)) {
      toast.error("Password must be at least 8 characters long and contain at least one letter.");
      return;
    }
  
    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
  
    setLoading(true);
    try {
      await registerUser({ email, username, password });
      localStorage.setItem("email", email);
      toast.success("Registration successful! Please verify your email.");
      setCurrentView("verify");
    } catch (error) {
      const errorMessage = error.response?.data?.detail || error.message;
      toast.error(errorMessage);
    }
    setLoading(false);
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    const { verificationCode, email } = formData;

    if (!verificationCode) {
      toast.error("Please enter the verification code.");
      return;
    }

    setLoading(true);
    try {
      const verifyResult = await postCheckCode(verificationCode, email);
      if (verifyResult.success) {
        toast.success("Email verification successful! You can now log in.");
        localStorage.removeItem("email");
        setCurrentView("login");
      } else {
        const errorMessage = verifyResult.errorData?.detail || "Invalid code.";
        toast.error(errorMessage);
      }
    } catch (error) {
      toast.error("Verification failed. Please try again.");
    }
    setLoading(false);
  };

  const handleResendCode = async (e) => {
    e.preventDefault();
    const { email } = formData;

    if (!email) {
      toast.error("Email not found. Please register again.");
      setCurrentView("register");
      return;
    }

    setLoading(true);
    try {
      const resendResult = await postResendCode(email);
      if (resendResult.success) {
        toast.success("Verification code resent successfully.");
      } else {
        toast.error(resendResult.errorMessage);
      }
    } catch (error) {
      toast.error("Failed to resend verification code.");
    }
    setLoading(false);
  };

  return (
    <div className="auth">
      <div className="auth-container">
        {currentView !== "resetPassword" &&
          currentView !== "sendRecoveryLink" &&
          currentView !== "verify" && (
            <div className="tabs">
              <div
                className={`tab ${currentView === "register" ? "active" : ""}`}
                onClick={() => setCurrentView("register")}
              >
                Sign Up
              </div>
              <div
                className={`tab ${currentView === "login" ? "active" : ""}`}
                onClick={() => setCurrentView("login")}
              >
                Login
              </div>
            </div>
          )}

        {currentView === "login" && (
          <form onSubmit={handleLogin}>
            <label>e-mail*</label>
            <input
              className="form-input"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <label>Password*</label>
            <input
              className="form-input"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <button className="form-button" type="submit" disabled={loading}>
              {loading ? "CONFIRM ..." : "CONFIRM"}
            </button>

            <div className="forgot-password">
              <button
                type="button"
                onClick={() => setCurrentView("sendRecoveryLink")}
                className="forgot-link"
              >
                Forgot your password?
              </button>
            </div>
          </form>
        )}

        {currentView === "register" && (
          <form onSubmit={handleRegister}>
            <label>User name</label>
            <input
              className="form-input"
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
            <label>e-mail*</label>
            <input
              className="form-input"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <label>Password*</label>
            <input
              className="form-input"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <label>Repeat password*</label>
            <input
              className="form-input"
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
            <div className="checkbox-container">
              <label className="checkbox-label">
                <input type="checkbox" required />
                <span>I learned&nbsp;</span>
                <Link to="/legal/terms-of-use">Terms of Use.</Link>
              </label>
              <label className="checkbox-label">
                <input type="checkbox" required /> I am not a Robot.
              </label>
            </div>
            <button className="form-button" type="submit" disabled={loading}>
              {loading ? "CONFIRM ..." : "CONFIRM"}
            </button>
          </form>
        )}

        {currentView === "verify" && (
          <form onSubmit={handleVerifyCode} className="verify-form">
            <h2>Verify your e-mail address</h2>
            <p>
              We sent a verification code to your e-mail. Please, check your
              inbox and enter the code below to verify your account.
            </p>
            <div className="code-boxes">
              {[...Array(6)].map((_, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  name={`code-${index}`}
                  className="form-input"
                  ref={(el) => (codeRefs.current[index] = el)}
                  onChange={(e) => {
                    const newValue = e.target.value.replace(/[^0-9]/g, "");
                    setFormData((prev) => ({
                      ...prev,
                      verificationCode: `${prev.verificationCode.slice(
                        0,
                        index
                      )}${newValue}${prev.verificationCode.slice(index + 1)}`,
                    }));
                    if (newValue && index < 5) {
                      codeRefs.current[index + 1]?.focus();
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Backspace" && !e.target.value && index > 0) {
                      codeRefs.current[index - 1]?.focus();
                    }
                  }}
                  required
                />
              ))}
            </div>
            <p className="form-text" onClick={handleResendCode}>
              Not receive the code? <u>Resend</u>
            </p>
            <button className="form-button" type="submit" disabled={loading}>
              {loading ? "CONFIRM ..." : "CONFIRM"}
            </button>
          </form>
        )}

        {currentView === "sendRecoveryLink" && (
          <form onSubmit={handleSendRecoveryLink}>
            <div className="back-class">
              <button
                className="back-button"
                onClick={() => setCurrentView("login")}
              >
                <HiArrowUturnRight />
              </button>
            </div>
            <div className="recovery_content">
              <h2>Password Recovery</h2>
              <p>
                Please enter the email address associated with your account, and
                we'll send you a link to reset your password.
              </p>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                className="form-input"
              />
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              <button type="submit" className="form-button" disabled={loading}>
                {loading ? "Sending..." : "Send Recovery Link"}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default Auth;
