import React, { useEffect, useState } from "react";
import "./About.scss";
import { servicesList, servDescription } from "../../data";
import ActionPanel from "../../components/ActionPanel/ActionPanel";
import ContactForm from "../../components/ContactForm/ContactForm";
import back_cover from "../../assets/background/back_cover.mp4";
import aboutImg from "../../assets/prev-Half.jpg";
import teamIcon from "../../assets/team.svg";
import TeamMemberCard from "./TeamMemberCard";
import { teamMembers } from "../../data";

const About = () => {
  const [selectedMember, setSelectedMember] = useState(teamMembers[0]);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 720);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 720);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const centerX = 300;
  const centerY = 200;

  const calculateNodePointsSmall = (
    centerX,
    centerY,
    radius,
    endX,
    endY,
    bendRatio
  ) => {
    const dx = endX - centerX;
    const dy = endY - centerY;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const startX = centerX + (dx / distance) * radius;
    const startY = centerY + (dy / distance) * radius;

    const bendX = startX + (dx / distance) * ((radius * bendRatio) / 6);
    const bendY = startY + (dy / distance) * (radius * bendRatio);

    return { startX, startY, bendX, bendY, endX, endY };
  };

  const distributeNodesSmall = (
    centerX,
    centerY,
    radius,
    bendRatio,
    spacing,
    total
  ) => {
    const nodes = [];
    const offsetY = (spacing * (total - 1)) / 2;

    for (let i = 0; i < total; i++) {
      const yOffset = centerY - offsetY + i * spacing;
      const xLeft = centerX - 260;

      nodes.push(
        calculateNodePointsSmall(
          centerX,
          centerY,
          radius,
          xLeft,
          yOffset,
          bendRatio
        )
      );
    }

    return nodes;
  };

  const calculateNodePointsLarge = (
    centerX,
    centerY,
    radius,
    endX,
    endY,
    bendRatio
  ) => {
    const dx = endX - centerX;
    const dy = endY - centerY;
    const distance = Math.sqrt(dx * dx + dy * dy);
    const startX = centerX + (dx / distance) * radius;
    const startY = centerY + (dy / distance) * radius;

    const bendX = startX + (dx / distance) * (radius * bendRatio);
    const bendY = startY + (dy / distance) * (radius * bendRatio);

    return { startX, startY, bendX, bendY, endX, endY };
  };

  const distributeNodesLarge = (
    centerX,
    centerY,
    radius,
    bendRatio,
    spacing,
    total
  ) => {
    const nodes = [];
    const offsetY = (spacing * (total - 1)) / 2;

    for (let i = 0; i < total; i++) {
      const yOffset = centerY - offsetY + i * spacing;
      const xLeft = centerX - 260;
      const xRight = centerX + 260;

      nodes.push(
        calculateNodePointsLarge(
          centerX,
          centerY,
          radius,
          xLeft,
          yOffset,
          bendRatio
        ),
        calculateNodePointsLarge(
          centerX,
          centerY,
          radius,
          xRight,
          yOffset,
          bendRatio
        )
      );
    }

    return nodes;
  };

  const nodes = isMobileView
    ? distributeNodesSmall(centerX, centerY, 40, 5, 90, 6)
    : distributeNodesLarge(centerX, centerY, 60, 0.5, 200, 3);

  return (
    <div className="about-page">
      <div className="about-cover">
        <video className="about-video-rest" autoPlay muted loop playsInline>
          <source src={back_cover} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="about">
        <div className="about-container">
          <div className="image">
            <img src={aboutImg} alt="Artwork" className="about-image" />
          </div>
          <div className="about-content">
            <h2>MORE ABOUT FUNOONI</h2>
            <p>
              Funooni Platform is an innovative project by FunoonArab that
              empowers artists to share their creativity on a global scale, with
              a special focus on preserving and promoting traditional Arabic
              art. By merging art and blockchain technology, Funooni enables
              artists to tokenize their physical artworks, creating unique NFTs
              that bridge cultural heritage with modern digital expression. From
              painters and sculptors to designers and digital creators, Funooni
              provides a collaborative space for artists across the MENA region
              to connect, showcase, and expand their influence in the evolving
              art world.
            </p>
          </div>
        </div>
      </div>

      <div className="Vision_container">
        <div className="card">
          <h3 className="title">Our Vision</h3>
          <p className="text">
            To be the leading digital platform that bridges traditional and
            digital art, showcasing MENA artists worldwide.
          </p>
        </div>
        <div className="card">
          <h3 className="title">Our Mission</h3>
          <p className="text">
            Empowering artists by digitizing and tokenizing their work,
            transforming physical art into unique NFTs to celebrate and protect
            their creativity.
          </p>
        </div>
      </div>

      <ul className="about-segment-border">
        <li className="segment-field  ">
          <ul className="cmpny-serv-options">
            {servicesList.map((panel, index) => (
              <li className="serv_option_item" key={index}>
                <ActionPanel
                  link={panel.link}
                  title={panel.name}
                  text={panel.description}
                  img={panel.img}
                />
              </li>
            ))}
          </ul>
        </li>
      </ul>

      <div className="about-page2">
        <h2 className="core-team-title">FUNOONI CORE TEAM</h2>
        <div className="core-team-container">
          <div className="spider-diagram">
            <svg height="400" xmlns="http://www.w3.org/2000/svg">
              <circle
                cx={centerX}
                cy={centerY}
                r="60"
                fill="#fff"
                stroke="#000"
                strokeWidth="3"
                className="center-circle"
              />

              <image
                x="265"
                y="165"
                width="70"
                height="70"
                href={teamIcon}
                className="img1"
              />

              {nodes.map((node, index) => {
                const textAnchor = node.endX < 400 ? "start" : "end";
                const textX = node.endX < 400 ? node.endX + 17 : node.endX - 17;

                return (
                  <React.Fragment key={node.name}>
                    <polyline
                      points={`${node.startX},${node.startY} ${node.bendX},${node.bendY} ${node.endX},${node.bendY}`}
                      fill="none"
                      stroke="#000"
                      strokeWidth="2"
                      onClick={() => setSelectedMember(teamMembers[index])}
                    />
                    <circle
                      cx={node.endX}
                      cy={node.bendY}
                      r="12"
                      fill="#fff"
                      stroke="#000"
                      strokeWidth="3"
                      onClick={() => setSelectedMember(teamMembers[index])}
                    />
                    <foreignObject
                      x={node.endX - 10}
                      y={node.bendY - 10}
                      width="20"
                      height="20"
                      className="foreignObject1"
                    >
                      <div className="span2">
                        <span
                          className={`dot ${
                            selectedMember.name === teamMembers[index].name
                              ? "filled"
                              : ""
                          }`}
                          onClick={() => setSelectedMember(teamMembers[index])}
                        ></span>
                      </div>
                    </foreignObject>

                    <text
                      x={textX}
                      y={node.bendY - 10}
                      fontSize="15"
                      fontWeight="bold"
                      textAnchor={textAnchor}
                    >
                      {teamMembers[index]?.name || ""}
                    </text>
                    <text
                      x={textX}
                      y={node.bendY + 14}
                      fontSize="13"
                      fill="#636363"
                      textAnchor={textAnchor}
                    >
                      {teamMembers[index]?.role || ""}
                    </text>
                  </React.Fragment>
                );
              })}
            </svg>
          </div>
          <TeamMemberCard
            key={selectedMember.name}
            title={selectedMember.name}
            role={selectedMember.role}
            content={selectedMember.description}
            maxWords={46}
          />
        </div>
      </div>

      <div className="text-contain extra">{servDescription.desc2}</div>

      <ContactForm />
    </div>
  );
};

export default About;
