import { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "./Auth.scss";
import { hostURL } from "../../data_requests";

const ResetPasswordAPI = async (token, newPassword) => {
  try {
    const response = await axios.post(`${hostURL}/user/password_reset`, {
      token: token,
      new_password: newPassword,
    });

    return { success: true, data: response.data };
  } catch (error) {
    if (error.response) {
      return {
        success: false,
        errorMessage: error.response.data.message || "Server error occurred",
      };
    } else if (error.request) {
      return { success: false, errorMessage: "No response from the server" };
    } else {
      return { success: false, errorMessage: "Unexpected error occurred" };
    }
  }
};

const ResetPassword = () => {
  const [formData, setFormData] = useState({
    token: "",
    password: "",
    confirmPassword: "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get("token");
    if (token) {
      setFormData((prevFormData) => ({ ...prevFormData, token }));
    } else {
      toast.error("Invalid or missing token.");
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasLetter = /[a-zA-Z]/.test(password);
    const hasDigit = /\d/.test(password);
    return password.length >= minLength && hasLetter && hasDigit;
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    const { password, confirmPassword, token } = formData;

    // Password validation
    if (password !== confirmPassword) {
      setErrorMessage("The passwords do not match. Please make sure both fields are identical.");
      return;
    }

    if (!validatePassword(password)) {
      setErrorMessage("Password must be at least 8 characters long and contain at least one letter and one digit.");
      return;
    }

    setLoading(true);
    setErrorMessage(""); 

    try {
      const result = await ResetPasswordAPI(token, password);
      console.log("Response:", result);

      if (result.success) {
        setErrorMessage(""); 
        toast.success("Password reset successful! Please log in.");
      } else {
        setErrorMessage(result.errorMessage || "Something went wrong.");
        toast.error(result.errorMessage || "Something went wrong.");
      }
      
    } catch (error) {
      console.error("Reset password error:", error);
      setErrorMessage("An unexpected error occurred. Please try again.");
      toast.error("An unexpected error occurred. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="auth">
      <div className="auth-container">
        <form onSubmit={handleResetPassword}>
          <div className="recovery_content">
            <h2>Password Recovery</h2>
            <p>Please set a new password for your account</p>
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
              className="form-input"
              placeholder="New Password"
            />
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
              className="form-input"
              placeholder="Confirm Password"
            />
            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <button type="submit" className="form-button" disabled={loading}>
              {loading ? "Resetting..." : "Confirm Password"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
