import axios from "axios";
import React, { createContext, useContext, useState, useEffect } from "react";
import { hostURL } from "./data_requests";
import { toast } from "react-toastify";
import Web3 from "web3";

const WalletContext = createContext();

export const WalletProvider = ({ children }) => {
  const [ethAddress, setEthAddress] = useState(
    localStorage.getItem("ethAddress") || null
  );
  const [isConnecting, setIsConnecting] = useState(false);

  const walletConnection = async (authenticateWeb3) => {
    if (window.ethereum) {
      setIsConnecting(true);
      const web3 = new Web3(window.ethereum);
      try {
        await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const accounts = await web3.eth.getAccounts();
        const account = accounts[0];

        const authResult = await authenticateWeb3(account);

        if (authResult.success) {
          setEthAddress(account);
          localStorage.setItem("loginMethod", "MetaMask");
          localStorage.setItem("ethAddress", account);
          localStorage.setItem("isAuthenticated", true);

          console.log("MetaMask login successful");
          toast.success("MetaMask connected successfully!");
        } else {
          console.error("Authentication failed");
          toast.error("Authentication failed. Please try again.");
        }
      } catch (error) {
        console.error("Error during wallet connection:", error);
        toast.error("Error during wallet connection. Please try again.");
      } finally {
        setIsConnecting(false);
      }
    } else {
      console.error("MetaMask is not installed");
      toast.error("MetaMask is not installed. Please install MetaMask.");
    }
  };

  const connectWallet = async (authenticateWeb3) => {
    setIsConnecting(true);
    try {
      const address = await authenticateWeb3();
      setEthAddress(address);
    } catch (error) {
      console.error("Error connecting wallet:", error);
    } finally {
      setIsConnecting(false);
    }
  };
  const logoutWallet = () => {
    setEthAddress(null);
    localStorage.removeItem("ethAddress");
    localStorage.removeItem("loginMethod");
    toast.info("Logged out successfully.");
  };

  return (
    <WalletContext.Provider
      value={{
        ethAddress,
        setEthAddress,
        isConnecting,
        walletConnection,
        connectWallet,
        logoutWallet,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => useContext(WalletContext);
