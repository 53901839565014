import { Link, Route, Routes, useLocation, useParams } from "react-router-dom";
import { useState, useEffect, Suspense, lazy } from "react";
import "./App.scss";
import { useToFindItem, ScrollToTop } from "./data_requests";
import Preloader from "./components/Preloader/Preloader";
import Footer from "./components/Footer/Footer";
import Legal from "./routes/Legacy/Legal";
import About from "./routes/About/About";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Auth from "./routes/Auth/Auth";
import ResetPassword from "./routes/Auth/ResetPassword";
import Media from "./routes/Media/Media";
const Article = lazy(() => import("./components/Article/Article"));

const Home = lazy(() => import("./routes/Home/Home"));
const NavBar = lazy(() => import("./components/NavBar/NavBar"));
const MarketPage = lazy(() => import("./routes/MarketPage/MarketPage"));
const User = lazy(() => import("./routes/User/User"));
const Art = lazy(() => import("./routes/item/Art/Art"));
const Artist = lazy(() => import("./routes/item/Artist/Artist"));
const Admin = lazy(() => import("./admin/Admin"));
const NotFound = lazy(() => import("./routes/NotFound/NotFound"));
const PhyArt = lazy(() => import("./routes/item/Art/PhyArt"));

function App() {
  const location = useLocation();
  const [showCookieConsent, setShowCookieConsent] = useState(
    !localStorage.getItem("cookieConsent")
  );

  const isAccountPage =
    location.pathname === "/account" ||
    location.pathname === "/admin" ||
    location.pathname === "*";

  const handleAcceptCookies = () => {
    localStorage.setItem("cookieConsent", "true");
    setShowCookieConsent(false);
  };

  return (
    <div className="App">
      <ToastContainer
        position="top-center"
        className="toast"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {showCookieConsent && (
        <div className="cookie-consent">
          <div className="cookie-consent__content">
            <p>
              We use cookies to improve your experience and personalize
              marketing. By clicking "Accept All", you agree to our{" "}
              <Link to={"/legal/terms-of-use"}>user policy</Link>.
            </p>
            <div className="cookie-consent__actions">
              <Link to={"/legal/terms-of-use"}>Manage Terms of Use</Link>
              <button onClick={handleAcceptCookies}>Accept All</button>
            </div>
          </div>
        </div>
      )}
      <Suspense fallback={<Preloader />}>
        {!isAccountPage && <NavBar />}
        <ScrollToTop />
        <Routes>
          <Route path="/aGVsbG8gd29ybGQh" element={<Admin />} />
          <Route path="/" element={<Home />} />
          <Route path="/nfts" element={<MarketPage marketType="nfts" />} />
          <Route
            path="/artworks"
            element={<MarketPage marketType="artwork" />}
          />
          <Route path="/artists" element={<MarketPage marketType="artist" />} />
          <Route path="/media" element={<Media />} />
          <Route path="/media/:id" element={<Article />} />
          <Route
            path="/nft/:idhex"
            element={
              <DataWrapper
                key={window.location.pathname}
                type="nft"
                Component={Art}
              />
            }
          />
          <Route
            path="/artist/:idhex"
            element={
              <DataWrapper
                key={window.location.pathname}
                type="artist"
                Component={Artist}
              />
            }
          />
          <Route
            path="/artwork/:idhex"
            element={
              <DataWrapper
                key={window.location.pathname}
                type="artwork"
                Component={PhyArt}
              />
            }
          />

          <Route path="/about" element={<About />} />
          <Route path="/profile" element={<User />} />
          <Route path="/logout" />
          <Route path="/legal/:section" element={<Legal />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/auth" element={<Auth />} />
          <Route path="/pasword_reset" element={<ResetPassword />} />
        </Routes>
        <Footer />
      </Suspense>
    </div>
  );
}

function DataWrapper({ type, Component }) {
  const [loading, setLoading] = useState(true);
  const [pageID, setPageID] = useState();
  const { idhex } = useParams();
  const [name, id] = idhex.split("-");

  useEffect(() => {
    setPageID(id);
  }, [id]);

  const fetchData = useToFindItem(type, pageID);

  useEffect(() => {
    if (fetchData) {
      setLoading(false);
    }
  }, [fetchData]);

  if (loading) {
    return <Preloader />;
  }
  return <Component data={fetchData} />;
}

export default App;
